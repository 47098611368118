import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { defineStore } from "pinia";
import { useWeb3Store } from "../web3";

export const useGodApeQuestStore = defineStore("god-ape-quest", {
  state: () => ({
    team: "",
    communityPhase: 5,
    currentPhase: 1,
    completedCombinations: [],
    godApeMintSignatures: [],
    apeClaimSignatures: [],
    teenClaimSignatures: [],
    isLoading: false,
  }),
  getters: {
    canMintGodApe() {
      return this.godApeMintSignatures.length > 0;
    },
    canClaimApe() {
      return this.apeClaimSignatures.length > 0;
    },
    canClaimTeen() {
      return this.teenClaimSignatures.length > 0;
    },
    hasTeam() {
      return !!this.team;
    },
  },
  actions: {
    async init(isReset) {
      if (this.team && !isReset) return;
      this.isLoading = true;
      const res = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/quest/wallet",
        params: {
          network: Config.network.name,
          wallet: useWeb3Store().collectionWallet,
        },
      });
      if (!res) return;
      this.currentPhase = res.current_phase;
      this.completedCombinations = res.combinations_done || [];
      this.team = res.team;
      this.godApeMintSignatures = res.signatures;
      this.apeClaimSignatures = res.apeSignatures;
      this.teenClaimSignatures = res.teenSignatures;
      this.isLoading = false;
    },
  },
});
