import { defineStore } from "pinia";
import moment from "moment";
import { useHRApi } from "@/api/useHRApi";
import { Config } from "@/config";
import { useWeb3Store } from "./web3";
import { useUtilitiesInteractionsContract } from "@/web3/contracts";
import { useWalletCollectionStore } from "./wallet-collection";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { getApeBonus } from "@/helpers/get-ape-bonus";
export const useMethBankStore = defineStore("meth-bank", {
  state: () => ({
    balance: 0,
    lastHarvest: undefined,
    isHarvestedToday: false,
    lastClaimDate: undefined,
    nextClaimDate: undefined,
    canClaimNow: false,
    nextHarvestDate: undefined,
    isLoading: true,
  }),
  getters: {
    claimableMeth() {
      return this.balance;
    },
    harvestableMeth() {
      const walletCollection = useWalletCollectionStore();
      const { teens, botbTokens, partners } = walletCollection;
      const methMachines = walletCollection.onChainMethMachines;
      const uniquemethMachines = methMachines.filter((item, index) => {
        return (
          methMachines.findIndex((maker) => maker.guild === item.guild) ===
          index
        );
      });
      let methMakerTeens = [];
      uniquemethMachines.forEach((methMaker) => {
        const currentTeens = teens
          .filter((item) => {
            return item.guild === methMaker.guild && !item.isResurrected;
          })
          .map((teen) => teen.id);
        methMakerTeens = methMakerTeens.concat(currentTeens);
      });
      const methMakerTeensTotal = methMakerTeens.length * 5;
      let levelTeensTotal = 0;
      teens.forEach((teen) => {
        if (methMakerTeens.includes(teen.id) || teen.isResurrected) return;
        const bonus = getAttachedApe(teen) ? getApeBonus(teen) : 0;

        levelTeensTotal += (teen.harvestInfo.harvestPerDay || 0) + bonus;
      });
      let botbTotal = 0;
      // botbTokens.forEach((botbItem) => {
      //   botbItem.forEach((partner) => {
      //     const bonus = getAttachedApe(partner) ? getApeBonus(partner) : 0;
      //     botbTotal += (partner.harvestInfo.harvestPerDay || 0) + bonus;
      //   });
      // });

      let levelPartnersTotal = 0;
      // Object.keys(partners).forEach((key) => {
      //   partners[key].forEach((token) => {
      //     const bonus = getAttachedApe(token) ? getApeBonus(token) : 0;
      //     levelPartnersTotal += (token.harvestInfo.harvestPerDay || 0) + bonus;
      //   });
      // });
      const botbSetBonus = 0;
      // const botbSetBonus = walletCollection.botbSetsIds.length * 3;
      return (
        methMakerTeensTotal +
        levelTeensTotal +
        levelPartnersTotal +
        botbTotal +
        botbSetBonus
      );
    },
  },
  actions: {
    async fetchMethBank(silent) {
      if (!silent) {
        this.isLoading = true;
      }
      const web3Store = useWeb3Store();
      const methBankInfo = await useHRApi({
        throw: true,
        toast: { error: () => "Error while loading Meth Bank" },
      }).exec({
        url: "/v1/bap/meth-balance",
        params: {
          wallet_address: web3Store.collectionWallet,
          network: Config.network.name,
        },
      });
      const lastHarvest = moment.unix(methBankInfo.last_harvest);
      // every 17 UTC
      const resetDate = moment();
      const currentDate = moment();

      resetDate.tz("America/Chicago");
      resetDate.minute(0).second(0).hour(0);

      if (resetDate.diff(currentDate) < 0) {
        resetDate.days(resetDate.days() + 1);
      }

      const lastPrevClaim = moment(resetDate);
      lastPrevClaim.days(lastPrevClaim.days() - 1);
      this.isHarvestedToday = lastHarvest.diff(lastPrevClaim) > 0;
      const utilitiesInteractionsContract = useUtilitiesInteractionsContract();
      const [lastClaimUnix, claimCoolDownSeconds] = await Promise.all([
        utilitiesInteractionsContract.methods
          .lastWithdraw(web3Store.walletAddress)
          .call(),
        utilitiesInteractionsContract.methods.withdrawCoolDown().call(),
      ]);
      const claimCoolDown = claimCoolDownSeconds / 60 / 60;
      const lastClaim = moment.unix(lastClaimUnix);
      this.canClaimNow = moment().diff(lastClaim, "hours") >= claimCoolDown;
      this.lastClaimDate = lastClaim.toDate();
      this.nextClaimDate = moment(lastClaim)
        .add(claimCoolDown, "hours")
        .toDate();

      this.balance = methBankInfo.balance;
      this.lastHarvest = lastHarvest;
      this.nextHarvestDate = resetDate.toDate();

      this.isLoading = false;
    },
  },
});
