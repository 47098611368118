<template>
    <div class="formulas-actions">
        <a href="javascript:void(0)" class="formulas-actions__burn" @click.prevent="$emit('burn')">
            <IFire width="18" />Create Forge Block
        </a>
        <a href="javascript:void(0)" class="formulas-actions__god-ape" @click.prevent="$emit('mintGodApe')"
            v-if="canMintGodApe">
            <IMedal width="18" />Mint God Ape
        </a>
        <a href="javascript:void(0)" class="formulas-actions__god-ape" @click.prevent="$emit('claimApe')"
            v-if="canClaimApe">
            <IMedal width="18" />Claim Ape
        </a>
        <a href="javascript:void(0)" class="formulas-actions__god-ape" @click.prevent="$emit('claimTeen')"
            v-if="canClaimTeen">
            <IMedal width="18" />Claim Teen
        </a>
    </div>
</template>
<script setup>
import IFire from "@/assets/icons/fire.svg?inline"
import IMedal from "@/assets/icons/medal.svg?inline"
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { computed } from "vue-demi";
defineEmits(['burn', 'mintGodApe', 'claimApe', 'claimTeen'])
const canMintGodApe = computed(() => useGodApeQuestStore().canMintGodApe)
const canClaimApe = computed(() => useGodApeQuestStore().canClaimApe)
const canClaimTeen = computed(() => useGodApeQuestStore().canClaimTeen)
</script>
<style lang="scss">
.formulas-actions {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    &__god-ape,
    &__burn {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #fff;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 5px;
        transition: 0.2s;

        svg {
            margin-right: 5px;
        }
    }

    &__burn {
        background-color: var(--gray-light);
        border: 1px solid var(--border);

        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    &__god-ape {
        background-color: var(--purple);
        margin-left: 20px;
        box-shadow: 0 0 20px 2px var(--purple);

        &:hover {
            filter: brightness(0.8);
        }
    }

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;

        &__god-ape {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}
</style>