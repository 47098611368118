export const ModalName = {
  HarvestModal: "HarvestModal",
  ConfirmModal: "ConfirmModal",
  SendMethModal: "SendMethModal",
  TeenGeneratedModal: "TeenGeneratedModal",
  WrongNetworkModal: "WrongNetworkModal",
  ApeConstructorAnalyzeModal: "ApeConstructorAnalyzeModal",
  LootBoxOpenModal: "LootBoxOpenModal",
  BuyMethModal: "BuyMethModal",
  ApeMintModal: "ApeMintModal",
  LootBoxListModal: "LootBoxListModal",
  ImageModal: "ImageModal",
  ApeRefundModal: "ApeRefundModal",
  DepositMethBankModal: "DepositMethBankModal",
  HarvestLimitModal: "HarvestLimitModal",
  SendTrait: "SendTrait",
  FormulasPrizeModal: "FormulasPrizeModal",
  GodApeQuestStartModal: "GodApeQuestStartModal",
  GodApeQuestFinishModal: "GodApeQuestFinishModal",
  SellTraitModal: "SellTraitModal",
  BuyTraitModal: "BuyTraitModal",
  SendUtilityModal: "SendUtilityModal",
  GodApeGeneratedModal: "GodApeGeneratedModal",
  CreateConstructorBackgroundModal: "CreateConstructorBackgroundModal",
  ClaimApeModal: "ClaimApeModal",
  ClaimTeenModal: "ClaimTeenModal",
};
