<template>
  <div class="formulas-gac">
    <FormulasApeTicketApe :items="apeTicketFormulas" @select="$emit('select', $event)" />
    <div class="formulas-gac-phase__title">
      <span>Get Methane Crystals</span>
    </div>
  </div>
  <div class="formulas-items">
    <div class="formulas-items__item" v-for="item in otherFormulas" :key="item">
      <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
    </div>
  </div>
  <div class="formulas-gac">
    <div class="formulas-gac-phase__title">
      <span>Get Meth</span>
    </div>
  </div>
  <div class="formulas-items">
    <div class="formulas-items__item" v-for="item in methFormulas" :key="item">
      <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
    </div>
  </div>
  <div class="formulas-gac">
    <div class="formulas-gac-phase__title">
      <span>Get Utilities</span>
    </div>
  </div>
  <div class="formulas-items">
    <div class="formulas-items__item" v-for="item in utilitiesFormulas" :key="item">
      <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue-demi";
import FormulasFormulaCard from "../FormulasFormulaCard/FormulasFormulaCard.vue";
import FormulasApeTicketApe from "./FormulasApeTicketApe.vue";
const props = defineProps({ items: Array });
defineEmits(["select"]);

const apeTicketFormulas = computed(() =>
  props.items.filter((item) => item.trait_out.type === "ape_from_ticket" || item.trait_out.type === "teen_from_ticket")
);
const otherFormulas = computed(() => {
  return props.items.filter(
    (item) =>
      item.trait_out.type !== "ape_from_ticket" && item.trait_out.type !== "teen_from_ticket" &&
      item.combination_id.startsWith("AT")
  );
});
const utilitiesFormulas = computed(() => {
  return props.items.filter(
    (item) =>
      item.trait_out.type !== "ape_from_ticket" && item.trait_out.type !== "teen_from_ticket" &&
      item.combination_id.startsWith("UT")
  );
});
const methFormulas = computed(() => {
  return props.items.filter(
    (item) =>
      item.combination_id.startsWith("METH")
  );
});

</script>
<style lang="scss">
.formulas-items {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -10px;
  margin-top: 20px;
  position: relative;
  max-height: 80vh;
  overflow: auto;

  &__item {
    margin: 10px;
    width: calc(100% / 3 - 20px);
  }

  @media screen and (max-width: 1400px) {
    &__item {
      width: calc(100% / 2 - 20px);
    }
  }

  @media screen and (max-width: 1200px) {
    &__item {
      width: calc(100% / 3 - 20px);
    }
  }

  @media screen and (max-width: 768px) {
    &__item {
      width: calc(100% / 2 - 20px);
    }
  }

  @media screen and (max-width: 576px) {
    margin: 0 -5px;
    padding: 5px;
    margin-top: 20px;

    &__item {
      width: 100%;
      margin: 10px 0;
    }
  }
}

.formulas-gac {
  overflow: auto;
  max-height: 80vh;
  margin: -10px;
  margin-top: 30px;
  margin-bottom: 0;

  &-items {
    justify-content: center;
    overflow: visible !important;
    max-height: none !important;
    margin: 0 !important;
  }

  &-phase {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
      padding: 5px 0;

      span {
        margin: 0 10px;
      }

      &::before {
        content: "";
        height: 1px;
        display: block;
        background: rgba(255, 255, 255, 0.5);
        flex: 1;
      }

      &::after {
        content: "";
        height: 1px;
        display: block;
        background: rgba(255, 255, 255, 0.5);
        flex: 1;
      }
    }

    &-team {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &-vyl & {
        &__title {
          color: var(--ape-quest-vyl);
        }
      }

      &-ava & {
        &__title {
          color: var(--ape-quest-ava);
        }
      }

      &-all & {
        &__title {}
      }

      &__title {
        text-align: center;
        font-size: 18px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__info {
        margin-left: 5px;

        svg {
          transform: translateY(2px);
        }
      }

      &__progress {}

      &__completed {
        border: 1px solid var(--green);
        color: var(--green);
        padding: 1px 5px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & &__item {
        width: calc(100% / 2 - 20px);

        @media screen and (max-width: 1200px) {
          width: calc(100% / 2 - 20px);
        }

        @media screen and (max-width: 576px) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
